<i18n>
{
    "ru": {

    }
}
</i18n>
<template>
    <div class="search-field"
        :class="{'search-field_disabled': disabled}"
        :data-state="isLoading || loading ? 'loading' : state">

        <app-form class="form search-field__form">
            <form @submit.prevent
                :class="$slots.sort ? 'search-field__wrapper' : ''">
                <form-field
                    rules="required|min:2"
                    class="form__field search-field__field"
                    :class="fieldClasses">
                    
                    <!-- нужно доработать: для блока диагностики не должно быть атрибута value  -->
                    <input 
                        v-if="disableValueAttr"
                        type="text"
                        id="search-field"
                        ref="input"
                        @input="setQuery($event.target.value)"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        class="form__input search__input" />

                    <input 
                        v-else
                        type="text"
                        id="search-field"
                        ref="input"
                        :value="value ? decodeURIComponent(value) : ''"
                        @input="setQuery($event.target.value)"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        class="form__input search__input" />

                    <span class="search-field__reset" @click="reset">
                        <slot name="icon"></slot>
                    </span>
                </form-field>

                <slot class="search-field__sort" name="sort"></slot>
            </form>
        </app-form>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import trim from 'lodash/trim'

export default {
    name: 'SearchField',
    props: {
        placeholder: {
            type: [Number, String],
            default: () => {}
        },
        value: {
            type: [String, Number],
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            default: () => false
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        withSort: {
            type: Boolean,
            default: false
        },
        withSortAndFilter: {
            type: Boolean,
            default: false
        },
        disableValueAttr: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            q: null,
            state: this.value ? 'results' : null,
            timeout: null,
            loading: false
        }
    },
    computed: {
        ...mapState('entities', ['counters']),
        computedQuery: {
            get() {
                return this.query;
            },
            set(value) {
                this.q = value;
                this.$emit('input', value)
            }
        },
        fieldClasses() {
            return {
                'search-field__field_with-sort': this.withSort,
                'search-field__field_with-sort-filter': this.withSortAndFilter
            }
        }
    },
    watch: {
        value(value, old) {
            value = trim(value);
            !this.timeout || clearTimeout(this.timeout);
            
            if (!value || value.length < 2) {
                this.state = null;
            } else {
                this.state = 'results';
            }
        }
    },
    methods: {
        reset() {
            if(this.disableValueAttr){
                this.$emit('input', '');
                this.$refs.input.value = '';
            }else{
                this.setQuery(null);
            }
        },
        focus() {
            const input = this.$refs.input

            input.focus()
            input.selectionStart = input.value.length
        },
        setQuery(value) {
            if (value && value.length < 2) {
                return;
            }

            !this.timeout || clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$emit('input', value);
            }, 1000);
        },
        inputFocus() {
            this.$refs.input.focus();
        }
    }
};
</script>

<style lang="less">
.search-field {
    &_disabled {
        opacity: 0.5;
    }

    &[data-state="loading"] {
        .search-field {
            &__reset {
                background: url('~static/img/loading-sm.gif') no-repeat 50%;
            }
        }
    }

    &[data-state="results"] {
        .search-field {
            &__reset {
                pointer-events: auto;

                &:before {
                    opacity: 1;
                }
            }
        }
    }

    &__field {
        position: relative;
        .wi(search, 16, 16, 14, 16);

        &:before {
            top: rem(14);
            left: rem(20);
            z-index: 1;

            pointer-events: none;
        }

        &_with-sort {
            width: 100%;
            max-width: 80%;

            @{desktop} & {
                max-width: 64%;
            }
        }

        &_with-sort-filter {
            width: 100%;
            max-width: rem(645);
        }
    }

    &__input {
        background-color: @color-bg-gray-minor;
        border-bottom: rem(1) solid @color-gray-white;
        border-radius: 0;
        border-top: rem(1) solid @color-gray-white;
        text-overflow: ellipsis;
        white-space: nowrap;

        padding-left: rem(45);

        overflow: hidden;
    }

    &__reset {
        .wi(icon-close-gray, 16, 16);

        display: block;

        width: rem(54);

        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;

        pointer-events: none;

        @{desktop} & {
            cursor: pointer;
        }

        &:before {
            background-size: 100%;

            margin: rem(-8) 0 0 rem(-8);

            top: 50%;
            left: 50%;

            opacity: 0;
        }

        &:hover:before {
            @media @hover {
                background-image: url('~static/sprite/svg/icon-close.svg');
            }
        }
    }

    &__results {
        background-color: @color-white;
        box-sizing: border-box;

        width: 100%;

        position: fixed;
        top: rem(100);
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    &__tabs {
        height: 100%;
    }

    &__empty {
        color: @color-gray;
        text-align: center;

        padding: rem(50);
    }

    &__wrapper {
        @{desktop} & {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            position: relative;
        }

    }
}
</style>
