<template>
    <figure class="language-switcher">
        <button
            :disabled="isDisabled('ru')"
            @click="change"
            value="ru">RU</button>
        <button
            :disabled="isDisabled('en')"
            @click="change"
            value="en">EN</button>
    </figure>
</template>

<script>
export default {
    name: 'LanguageSwitcher',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: String,
            default: 'ru'
        }
    },
    computed: {},
    methods: {
        isDisabled(lang) {
            return this.value === lang
        },
        change(e) {
            this.$emit('change', e.target.value)
        }
    }
}
</script>

<style lang="less">
.language-switcher {
    display: flex;

    border: rem(1) solid @color-gray-white;
    border-radius: rem(50);
    color: @color-gray-light;
    background: @color-gray-white;
    outline: none;

    margin: 0;
    padding: 0;

    height: rem(28);
    width: rem(64);

    position: relative;

    overflow: hidden;

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        background: @color-white;
        border: none;
        color: @color-gray-light;
        font-size: rem(11);
        outline: none;
        text-align: center;

        padding: rem(2) rem(8) 0;

        height: 100%;
        width: 50%;

        transition: background-color .15s ease;

        &[disabled] {
            background: @color-gray-white;

            pointer-events: none;
        }

        .hover({
            color: @color-gray;
        });
    }
}
</style>
