<i18n>
{
    "ru": {
        "link_back": "Назад"
    }
}
</i18n>

<template>
    <ul
        v-if="items.length"
        class="breadcrumb"
        :class="`breadcrumb_${classModificator}`"
        itemscope
        itemtype="https://schema.org/BreadcrumbList"
        aria-label="Breadcrumb"
        role="navigation">

        <li v-show="getPrevPageName && showPrevButton"
            role="navigation"
            class="breadcrumb__item breadcrumb__item_back">
            <span @click="goBack"
                class="breadcrumb__link breadcrumb__link_back">
                <svg-icon name="chevron-right-current" class="breadcrumb__icon breadcrumb__icon_back" />

                <span itemprop="name">{{ $t('link_back') }}</span>

            </span>
            <div class="breadcrumb__link-separator"></div>
        </li>

        <li class="breadcrumb__item"
            role="navigation"
            v-for="(item, index) in items"
            :class="item.returnBack ? 'breadcrumb__item_return-btn' : ''"
            itemprop="itemListElement"
            :key="item.path"
            itemscope
            itemtype="https://schema.org/ListItem">

            <svg-icon
                v-if="item.returnBack"
                name="chevron-right"
                class="breadcrumb__icon breadcrumb__icon_rotate"
                :key="item.path"/>

            <html-link
                exact
                v-if="items[index + 1]"
                class="breadcrumb__link"
                itemprop="item"
                itemtype="https://schema.org/WebPage"
                :url="item.path">

                <!-- eslint-disable-next-line -->
                <span itemprop="name" v-html="item.name"></span>
            </html-link>

            <!-- eslint-disable-next-line -->
            <span v-else v-html="item.name"
                class="breadcrumb__link"
                itemprop="name"></span>

            <meta itemprop="position" :content="index" />

            <svg-icon
                v-if="!item.returnBack"
                name="chevron-right"
                class="breadcrumb__icon"
                :key="item.path"/>

        </li>

    </ul>
</template>

<script>

export default {
    name: 'Breadcrumb',
    props: {
        showPrevButton: {
            type: Boolean,
            default: false
        },
        prevPathName: {
            type: String,
            default: null
        },
        prevPathUrl: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: () => []
        },
        classModificator: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedPrevPathParams() {
            if(this.prevPathName) {
                return {name: this.prevPathName}
            }
            else if(this.prevPathUrl) {
                return this.prevPathUrl
            }

            return '/'
        },
        getPrevPageName() {
            if(process.client && sessionStorage && sessionStorage.prevPage) {
                return sessionStorage.prevPage
            }
            return undefined
        }
    },
    methods: {
        goBack() {
            // this.prevPathUrl ? this.$router.push(this.prevPathUrl) : this.$router.go(-1)
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="less">
.breadcrumb {
    display: block;

    color: @color-gray;
    font-size: rem(13);
    line-height: rem(44);
    list-style: none;
    white-space: nowrap;

    margin-bottom: rem(10);
    padding-left: rem(20);

    height: rem(44);
    max-width: 100%;

    position: relative;
    z-index: 0;

    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    user-select: none;

    .scrollbar-hidden();

    @{desktop} & {
        //overflow: auto;
        padding-left: 0;

        overflow: hidden;
    }

    &__item {
        display: inline-block;

        padding-right: rem(8);

        &:first-child:not(&_back),
        &:last-child {
            margin-left: 0;
        }

        &_back {
            margin-right: 0;
            margin-left: rem(-7);

            @{desktop} & {
                margin-left: 0;
            }
        }

        &:last-child {
            padding-right: rem(20);

            pointer-events: none;
        }
    }

    &__link {
        // color: @color-gray-light;
        color: #767676;

        margin-right: rem(6);

        transition: color .15s ease;

        .hover({
            color: @color-black;
        });

        &_back {
            cursor: pointer;

            color: @color-black;

            margin-right: rem(10);

            .hover({
                color: @color-violet;
            });
        }
    }

    &__icon {
        height: rem(12);
        width: rem(12);

        transform: translateY(rem(-1));

        &:not(.breadcrumb__icon_back) {
            color: @color-gray-light;
        }

        &_back {
            margin-right: rem(3);

            transform: rotate(180deg) translateY(rem(1));
        }
    }

    &__item:last-child &__link {
        // color: @color-black;
        margin-right: 0;
    }

    &__item:last-child &__icon {
        display: none;
    }

    &__link-separator {
        display: inline-block;

        background: @color-black;

        height: rem(12);
        width: rem(1);

        position: relative;
        top: rem(2);
    }

    &_profile-pages {
        margin-bottom: rem(3);

        @{mobile} & {
            margin-bottom: 0;
        }
    }

    &_no-margin-bottom {
        margin-bottom: 0;
    }
}
</style>
