<template>
    <div ref="loaderWrapper"
        class="scroll-loader__wrapper"
        :class="rootClasses">
        <div v-if="isLoadingTop"
            class="scroll-loader__preloader"
            :class="preloaderClasses">
            <img v-lazy-load data-src="/img/loading-sm.gif" alt="" />
        </div>
        <slot></slot>
        <div v-if="isLoading"
            class="scroll-loader__preloader"
            :class="preloaderClasses">
            <img v-lazy-load data-src="/img/loading-sm.gif" alt="" />
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';

export default {
    name: 'ScrollLoader',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        isLoadingTop: {
            type: Boolean,
            default: false
        },
        isGridWrapper: {
            type: Boolean,
            default: false
        },
        modeBottom: {
            type: Boolean,
            default: true
        },
        invertedScroll: {
            type: Boolean,
            default: false
        },
        innerScrollWatcher: {
            type: Boolean,
            default: false
        },
        scrollEventOffset: {
            type: [Number, String],
            default: 150
        }
    },
    data() {
        return {
            scrollPos: 0
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        debounceValue() {
            // return this.isDeviceDesktop ? 0 : 700
            return 0
        },
        rootClasses() {
            return {
                'scroll-loader__wrapper_grid' : this.isGridWrapper
            }
        },
        preloaderClasses() {
            return {
                'scroll-loader__preloader_absolute' : this.isGridWrapper
            }
        },
    },
    mounted() {
        if(this.innerScrollWatcher) {
            this.$refs.loaderWrapper.addEventListener('scroll', this.wrapperHandleScroll);
        }
        else {
            window.addEventListener('scroll', this.windowHandleScroll);
        }

        this.debouncedEmit = debounce(function(direction) {
            this.$emit('onLoad', direction)
        }, this.debounceValue)
    },
    destroyed() {
        if(!this.innerScrollWatcher) {
            window.removeEventListener('scroll', this.windowHandleScroll);
        }
    },
    methods: {
        debouncedEmit() {},
        detectScrollDirection() {
            if (this.$refs.loaderWrapper.scrollTop < this.scrollPos) {
                this.scrollPos = this.$refs.loaderWrapper.scrollTop;
                return 'top';
            }

            else {
                this.scrollPos = this.$refs.loaderWrapper.scrollTop;
                return 'bottom';
            }

        },
        wrapperHandleScroll(e) {
            const wrapperScrollHeight = e.target.scrollHeight - e.target.offsetHeight;
            const direction = this.detectScrollDirection();

            if(!this.isDeviceDesktop && direction === 'bottom' && e.target.scrollTop < 0) {
                // прослушиваем скролл вверх для мобильников (свайп)
                this.debouncedEmit('top');
            }

            else if(direction === 'top' && e.target.scrollTop <= 1 && e.target.scrollTop >= 0 ) {
                // прослушиваем скролл вверх
                this.debouncedEmit('top');
            }

            else if (direction === 'bottom' &&
                e.target.scrollTop >= wrapperScrollHeight - this.scrollEventOffset) {

                // прослушиваем скролл вниз
                this.debouncedEmit('bottom');
            }
        },
        windowHandleScroll() {
            if (window.innerHeight > this.$refs.loaderWrapper.getBoundingClientRect().bottom - this.scrollEventOffset) {
                this.debouncedEmit();
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                this.$refs.loaderWrapper.scrollTop = this.$refs.loaderWrapper.scrollHeight;
                return true;
            })
        }
    }
};
</script>

<style lang="less">
.scroll-loader {
    &__wrapper {
        position: relative;

        &_grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__preloader {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: rem(12);

        &_absolute {
            position: absolute;
            top: 100%;
            left: 50%;

            transform: translateX(-50%);
        }
    }
}
</style>
