<template>
    <label
        class="popup-checkbox"
        :class="classes">

        <input
            class="popup-checkbox__input"
            :type="inputType"
            :readonly="readonly"
            :disabled="disabled"
            :value="value"
            :required="required"
            v-model="inputValue" />

        <span class="popup-checkbox__label">
            <slot ></slot>
        </span>

    </label>
</template>

<script>
import CheckboxMixin from '@/mixins/checkbox.mixin.js'

export default {
    name: 'PopupCheckbox',
    mixins: [CheckboxMixin],
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        nowrap: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        bordered: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'popup-checkbox_bordered': this.bordered,
                'popup-checkbox_nowrap': this.nowrap,
                'popup-checkbox_disabled': this.disabled
            }
        }
    }
}
</script>

<style lang="less">
.popup-checkbox {
    display: block;
    cursor: pointer;

    margin: 0;
    padding: rem(12) @dim-page-padding;

    width: 100%;

    .hover({
        background: @color-gray-light-2;
    });

    &__input {
        display: none;
    }

    &__label {
        display: block;
        cursor: pointer;

        font-size: rem(16);

        position: relative;

        transition: color .15s ease;

        &:after {
            content: '';
            display: block;

            background-image: url('/sprite/svg/icon-check-primary.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: rem(14);

            height: rem(14);
            width: rem(14);

            position: absolute;
            top: 50%;
            right: 0;

            opacity: 0;

            transition: opacity .15s ease;

            transform: translateY(-50%);
        }
    }

    &__input:checked + &__label {
        color: @color-primary;

        &:after {
            opacity: 1;
        }
    }

    &__input[readonly] + &__label {
        color: @color-black;

        &:after {
            display: none;
        }
    }

    &_bordered {
        border-bottom: rem(1) solid @color-gray-white;
    }

    &_nowrap &__label {
        text-overflow: ellipsis;
        white-space: nowrap;

        padding-right: rem(24);

        overflow: hidden;
    }

    &_disabled &__label {
        color: @color-gray-light !important;

        &:after {
            display: none;
        }
    }

}
</style>
