<i18n>
	{
		"en": {
            "desc_name": "Z – A",
            "asc_name": "A – Z"
		},
		"ru": {
			"title": "Сортировка",
            "desc": "По убыванию",
            "asc": "По возрастанию",
            "desc_name_en": "Z – A",
            "asc_name_en": "A – Z",
            "desc_name": "Я – А",
            "asc_name": "А – Я"
		}
	}
</i18n>

<template>

    <modal-content
        class="popup-sort-modal"
        :class="rootClasses"
        :small-bottom="!isDeviceDesktop"
        no-padding-body>

        <template v-if="!isTooltip" #title>
            {{ $t('title') }}
        </template>

        <template #body>
            <div class="popup-sort">
                <div class="popup-sort__container">
                    <PopupCheckbox
                        v-for="(option, code) in options"
                        :key="code"
                        type="radio"
                        :value="code"
                        v-model="sortTypeValue">
                        {{ option.name }}
                    </PopupCheckbox>


                    <div v-if="withDefaultSortOrder" class="popup-sort__delimiter"></div>

                    <PopupCheckbox
                        v-if="withDefaultSortOrder"
                        type="radio"
                        :value="isSortByName ? 'asc' : 'desc'"
                        v-model="sortOrderValue">
                        {{ isSortByName ? $t('asc_name' + enPostfix) : $t('desc') }}
                    </PopupCheckbox>

                    <PopupCheckbox
                        v-if="withDefaultSortOrder"
                        type="radio"
                        :value="isSortByName ? 'desc' : 'asc'"
                        v-model="sortOrderValue">
                        {{ isSortByName ? $t('desc_name' + enPostfix) : $t('asc') }}
                    </PopupCheckbox>
                </div>
            </div>
        </template>
    </modal-content>

</template>

<script>
import { mapGetters } from 'vuex'
import ModalContent from '@/components/modal/ModalContent'
import PopupCheckbox from '@/components/ui/PopupCheckbox'

export default {
    name: 'PopupSort',
    components: {
        ModalContent,
        PopupCheckbox
    },
    props: {
        options: {
            type: Object,
            default: () => {}
        },
        enOrder: {
            type: Boolean,
            default: false
        },
        order: {
            type: String,
            default: ''
        },
        sort: {
            type: String,
            default: ''
        },
        withDefaultSortOrder: {
            type: Boolean,
            default: true
        },
        isTooltip: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        enPostfix() {
            return this.enOrder ? '_en' : ''
        },
        isSortByName() {
            return this.sort === 'name'
        },
        descClass() {
            return {
                'popup-sort__desc-last': this.isSortByName
            }
        },
        sortOrderValue: {
            get() {
                return this.order
            },
            set(value) {
                this.$bus.$emit('sort-order-change', value)
                this.hide();
            }
        },
        sortTypeValue: {
            get() {
                return this.sort
            },
            set(value) {
                // const order = value === 'name' ? 'asc' : 'desc'
                // this.$bus.$emit('sort-order-change', order)
                
                this.$bus.$emit('sort-type-change', value);
                this.hide();
            }
        },
        rootClasses() {
            return {
                'popup-sort-modal_tooltip': this.isTooltip
            }
        }
    },
    methods: {
        hide() {
            this.$modal.hide()
        }
    }
}
</script>

<style lang="less">
.popup-sort {

    &__container {
        display: flex;
        flex-flow: column;

        @{desktop} & {
            padding: rem(8) 0;
        }
    }

    &-modal {
        padding-right: 0;
        padding-left: 0;

        max-width: none;
        @{desktop} &{
            min-width: rem(220);
        }

        &__title {
            .page-padding();
        }

        &_tooltip {
            background-color: @color-white;
            border-radius: rem(3);
            box-shadow: 0 rem(3) rem(16) rgba(0, 0, 0, 0.08);

            padding: 0;

            height: auto;
            min-width: rem(260);

            position: relative;
            top: rem(-2);
            z-index: 100;

            overflow: hidden;

            transition: opacity 0.3s ease-in-out;
        }
    }

    &__delimiter {
        padding-top: rem(12);
        padding-bottom: rem(15);

        @{desktop} & {
            padding-bottom: rem(12);
        }

        &:after {
            content: '';
            display: block;
            background-color: @color-gray-white;
            height: rem(1);
            width: 100%;
            position: absolute;
            left: 0;
        }
    }

    &__desc-last {
        order: 9999
    }
}
</style>
