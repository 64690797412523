<i18n>
{
    "ru": {
        "sort": "Сортировка",
        "filter": "Фильтр",
        "results_not_found": "Результаты не найдены",
        "search": "Идёт поиск...",
        "empty_all": "Нет материалов",
        "empty_start": "По запросу",
        "empty_end": "ничего не найдено.",
        "show_more":  "Показать ещё {perPage}",
        "perfumes_one": "аромат",
        "perfumes_few": "аромата",
        "perfumes_many": "ароматов",
        "all": "Все"
    }
}
</i18n>
<template>
    <div class="catalog-list" :sticky-container="!isDeviceDesktop">

        <!-- Поле поиска -->
        <SearchField
            ref="search"
            v-if="withSearch"
            class="catalog-list__search"
            :class="searchClasses"
            v-model="searchQuery"
            :with-sort="isDeviceDesktop && sortInSearchField"
            :with-sort-and-filter="isDeviceDesktop && filterInSearchField"
            :is-loading="!!(searchLoading && searchQuery)"
            :disabled="searchDisabled"
            :placeholder="placeholder">
            <template v-if="isDeviceDesktop && sortInSearchField" #sort>
                <transition
                    v-if="withSort"
                    mode="out-in"
                    name="pop">
                    <div
                        @mouseover="showSortPopup"
                        @mouseleave="hideSortPopup"
                        class="catalog-list__sort-wrapper catalog-list__sort-wrapper_search"
                        :class="searchSortClasses">
                        <!-- Кнопка сортировки -->
                        <button
                            class="catalog-list__sort catalog-list__sort_search"
                            :key="sortType + sortOrder"
                            :disabled="isSortDisabled">

                            {{ $t('sort') }}:

                            <span class="catalog-list__sort-type">
                                {{ getSortType }}
                            </span>

                            <svg-icon
                                class="catalog-list__sort-icon"
                                :class="iconSortClass"
                                name="icon-chevron-down" />

                        </button>

                        <transition name="fade">
                            <PopupSort
                                v-if="popupSortShowed"
                                is-tooltip
                                :options="sortOptions"
                                :enOrder="enOrder"
                                :sort="sortType"
                                :order="sortOrder"
                                :withDefaultSortOrder="withDefaultSortOrder"/>
                        </transition>
                    </div>
                </transition>
                <!-- Кнопка фильтра -->
                <button
                    class="catalog-list__filter catalog-list__filter_search"
                    v-if="withFilter && filterInSearchField"
                    :disabled="filterDisabled"
                    @click="showFilter">

                    {{ $t('filter') }}

                    <span v-if="filtersCount">
                        ({{ filtersCount }})
                    </span>
                    <template v-else>+</template>
                </button>
            </template>
        </SearchField>

        <!-- Сортировка и фильтр -->
        <div v-if="showSortFilter"
            v-sticky="stickyFilter && !isDeviceDesktop"
            class="catalog-list__sort-and-filter">

            <transition
                v-if="withSort"
                mode="out-in"
                name="pop">

                <div
                    v-if="isDeviceDesktop"
                    @mouseover="showSortPopup"
                    @mouseleave="hideSortPopup"
                    class="catalog-list__sort-wrapper">
                    <!-- Кнопка сортировки -->
                    <button
                        class="catalog-list__sort"
                        :key="sortType + sortOrder"
                        :disabled="isSortDisabled">

                        {{ $t('sort') }}:

                        <span class="catalog-list__sort-type">
                            {{ getSortType }}
                        </span>

                        <svg-icon
                            class="catalog-list__sort-icon"
                            :class="iconSortClass"
                            name="icon-chevron-down" />

                    </button>

                    <transition name="slide">
                        <PopupSort
                            is-tooltip
                            v-if="popupSortShowed"
                            :options="sortOptions"
                            :enOrder="enOrder"
                            :sort="sortType"
                            :order="sortOrder"
                            :withDefaultSortOrder="withDefaultSortOrder"/>
                    </transition>
                </div>
                <!-- Кнопка сортировки -->
                <button
                    v-else
                    class="catalog-list__sort"
                    :key="sortType + sortOrder"
                    :disabled="isSortDisabled"
                    @click="showSort">

                    {{ $t('sort') }}:

                    <span class="catalog-list__sort-type">
                        {{ getSortType }}
                    </span>

                    <svg-icon
                        class="catalog-list__sort-icon"
                        :class="iconSortClass"
                        name="icon-chevron-down" />

                </button>
            </transition>

            <!-- Кнопка фильтра -->
            <button
                class="catalog-list__filter"
                v-if="withFilter"
                :disabled="filterDisabled"
                @click="showFilter">

                {{ $t('filter') }}

                <span v-if="filtersCount">
                    ({{ filtersCount }})
                </span>
                <template v-else>+</template>
            </button>
        </div>

        <!-- Сортировка буквами -->
        <div class="catalog-list__letters-slider-wrapper"
            :aria-hidden="!loaded"
            v-sticky="stickyLetters && !isDeviceDesktop"
            v-if="letters && letters.length">

            <div class="catalog-list__letters-slider-container"
                :data-switcher="lettersSwitcher">

                <div class="catalog-list__switcher-wrapper"
                    v-if="lettersSwitcher">

                    <LanguageSwitcher
                        class="catalog-list__switcher"
                        v-model="language"/>
                </div>

                <BaseScrollSlider
                    tag="ul"
                    id="letters-slider"
                    :data-justify="filteredLetters && filteredLetters.length < 20"
                    class="catalog-list__letters-slider">

                    <li v-if="showAllUrl" class="catalog-list__letter catalog-list__letter_all">
                        <a :href="getLetterTo()"
                            @click.prevent="handleLetterSelected(null)"
                            class="catalog-list__letter-link">
                            {{ $t('all') }}
                        </a>
                    </li>

                    <li
                        :key="index + letter"
                        :id="'letter-' + letter.name"
                        v-for="(letter, index) in filteredLetters"
                        class="catalog-list__letter">
                        <a v-if="letter"
                            rel="nofollow"
                            :href="getLetterTo(letter.name === activeLetter ? null : letter.name)"
                            @click.prevent="handleLetterSelected(letter.name === activeLetter ? null : letter)"
                            :class="activeLetterClass(letter.name)"
                            class="catalog-list__letter-link">
                            {{ letter.name }}
                        </a>
                        <template v-else>
                            {{ letter.name }}
                        </template>
                    </li>
                </BaseScrollSlider>
            </div>
        </div>

        <!-- Список элементов -->
        <template v-if="items && items.length">

            <transition-group v-if="!lazyLoading"
                class="catalog-list__list"
                :class="[listClasses, listGridColumns(gridColumns)]"
                tag="ul"
                name="list-complete">
                <li class="catalog-list__item list-complete-item"
                    :class="itemClasses"
                    v-for="(item, index) in items"
                    :id="'catalog-item-id-'+index"
                    :key="get(item, 'code', index)">

                    <!-- Элемент списка -->
                    <slot name="item"
                        :language="language"
                        :item="item">

                        {{ get(item, 'title') }}

                    </slot>
                </li>
            </transition-group>

            <transition v-else
                name="list-complete">
                <ScrollLoader class="catalog-list__list"
                    :class="[listClasses, listGridColumns(gridColumns)]"
                    :isLoading="loading"
                    :scrollEventOffset="200"
                    @onLoad="load">
                    <div class="catalog-list__item list-complete-item"
                        :class="itemClasses"
                        v-for="(item, index) in items"
                        :id="'catalog-item-id-'+index"
                        :key="get(item, 'code', index)">

                        <!-- Элемент списка -->
                        <slot name="item"
                            :language="language"
                            :item="item">

                            {{ get(item, 'title') }}

                        </slot>
                    </div>
                </ScrollLoader>
            </transition>

        </template>

        <div v-else class="catalog-list__empty-result_content">
            <div class="catalog-list__empty-text">
                {{ getEmptyText }}
            </div>
            <span class="btn btn_primary catalog-list__empty-reset"
                v-if="filtersCount"
                @click="$emit('reset-all')">
                Сбросить всё
            </span>
        </div>
        <!-- Пагинация -->
        <div v-if="withPagination"
            class="catalog-list__pagination-wrapper"
            :class="paginationClasses">

            <BasePagination
                v-if="pageCount > 1"
                class="catalog-list__pagination"
                v-model="paginationPage"
                :page-range="isDeviceDesktop ? 7 : 3"
                :page-count="pageCount" />

        </div>
        <div v-else-if="singleButtonPagination || searchLoading"
            class="catalog-list__pagination-wrapper catalog-list__pagination-wrapper_single-mode">
            <ButtonMore
                v-if="singleButtonPagination && !searchLoading"
                centered
                class="button-more_narrow"
                @click.native.prevent="showMore">
                {{ $t('show_more', { 'perPage': showMoreComputedCounter }) }}
            </ButtonMore>
            <div v-if="singleButtonPagination && searchLoading"
                class="scroll-loader__preloader catalog-list__pagination-preloader">
                <img v-lazy-load data-src="/img/loading-sm.gif" alt="loading..." />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import filter from 'lodash/filter'
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import url from '@/filters/url';
import SearchField from '@/components/layout/search/SearchField'
import PopupSort from '@/components/modal/catalog/PopupSort'
import LanguageSwitcher from '@/components/ui/LanguageSwitcher'
import ButtonMore from "~/components/ui/buttons/ButtonMore";
import ScrollLoader from "@/components/ui/ScrollLoader"

export default {
    name: 'CatalogList',
    components: {
        ButtonMore,
        LanguageSwitcher,
        SearchField,
        PopupSort,
        ScrollLoader
    },
    model: {
        prop: 'page',
        event: 'page-change'
    },
    props: {
        enOrder: {
            type: Boolean,
            default: false
        },
        lettersSwitcher: {
            type: Boolean,
            default: false
        },
        letterRouteName: {
            type: String,
            default: null
        },
        grid: {
            type: Boolean,
            default: false
        },
        gridColumns: {
            type: Number,
            default: null
        },
        activeLetter: {
            type: [String, Object],
            default: null
        },
        letters: {
            type: Array,
            default: () => []
        },
        lettersUrl: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        searchLoading: {
            type: Boolean,
            default: false
        },
        filtersCount: {
            type: Number,
            default: 0
        },
        withFilter: {
            type: Boolean,
            default: false
        },
        withSearch: {
            type: Boolean,
            default: true
        },
        withSort: {
            type: Boolean,
            default: true
        },
        withPagination: {
            type: Boolean,
            default: true
        },
        withDefaultSortOrder: {
            type: Boolean,
            default: true
        },
        filterDisabled: {
            type: Boolean,
            default: false
        },
        stickyLetters: {
            type: Boolean,
            default: false
        },
        stickyFilter: {
            type: Boolean,
            default: true
        },
        searchDisabled: {
            type: Boolean,
            default: false
        },
        sortOrder: {
            type: String,
            default: null
        },
        sortOptions: {
            type: Object,
            default: () => {}
        },
        defaultSort: {
            type: Object,
            default: () => {
                return {
                    order: 'desc',
                    type: 'name'
                }
            }
        },
        sortType: {
            type: String,
            default: null
        },
        query: {
            type: [String, Number],
            default: null
        },
        page: {
            type: Number,
            default: 1
        },
        pageCount: {
            type: Number,
            default: 0
        },
        placeholder: {
            type: String,
            default: null
        },
        itemMargin: {
            type: Boolean,
            default: false
        },
        sortInSearchField: {
            type: Boolean,
            default: false,
        },
        searchBorder: {
            type: Boolean,
            default: false,
        },
        searchFullBordered: {
            type: Boolean,
            default: false
        },
        showSortAndFilter: {
            type: Boolean,
            default: false,
        },
        paginationRightBar: {
            type: Boolean,
            default: false,
        },
        filterInSearchField: {
            type: Boolean,
            default: false,
        },
        searchBorderBottom: {
            type: Boolean,
            default: false,
        },
        singleButtonPagination: {
            type: Boolean,
            default: false,
        },
        singleButtonPaginationDeclensions: {
            type: Array,
            default: null,
        },
        totalItems: {
            type: [Number, String],
            default: 0
        },
        singlePaginationCaption: {
            type: [Number, String],
            default: 40
        },
        lazyLoading: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 100
        },
        list: {
            type: Object,
            default(){
                return {}
            }
        },
        showAllUrl: {
            type: Boolean,
            default: false
        }
    },
    globalBus: {
        'sort-type-change'(value) {
            this.internalSortType = value;
            this.$emit('change-sort-type', value);
            this.hideSortPopup();
        },

        'sort-order-change'(value) {
            this.internalSortOrder = value;
            this.$emit('change-sort-order', value);
            this.hideSortPopup();
        },

        'next-url-change'(value) {
            this.catalogNextPageUrl = value
        }
    },
    data() {
        return {
            loaded: false,
            internalSortType: null,
            internalSortOrder: null,
            internalQuery: null,
            internalPage: null,
            popupSortShowed: false,
            language: 'ru',
            loading: false,
            catalogNextPageUrl: this.list.nextPageUrl
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        showMoreComputedCounter() {
            const remainItems = this.totalItems - this.items.length;

            if (remainItems && remainItems > 0 &&
                remainItems < this.singlePaginationCaption) {
                return this.$options.filters.numberDeclension(remainItems, this.singleButtonPaginationDeclensions || this.perfumesDeclension);
            } else {
                return this.$options.filters.numberDeclension(this.singlePaginationCaption, this.singleButtonPaginationDeclensions || this.perfumesDeclension);
            }
        },
        perfumesDeclension() {
            return [
                this.$t('perfumes_one'),
                this.$t('perfumes_few'),
                this.$t('perfumes_many'),
            ];
        },
        filteredLetters() {
            if (!this.lettersSwitcher) return this.letters

            const regexp = this.language === 'ru' ? /[а-я]/i : /[a-z]/i
            return filter(this.letters, l => l.name.match(regexp))
        },
        getEmptyText() {
            if (this.searchLoading) return this.$t('search')
            if (!this.searchQuery) return this.$t('empty_all')
            return this.$t('empty_start') + ' "' + (this.searchQuery || '') + '" ' + this.$t('empty_end')
        },
        searchQuery: {
            get() {
                return this.query ? decodeURI(this.query) : undefined
            },
            set(value) {
                this.internalQuery = value
                this.emitQuery(value)
            }
        },
        paginationPage: {
            get() {
                return this.page
            },
            set(value) {
                this.internalPage = value
                this.$emit('page-change', value)
            }
        },
        listClasses() {
            return {
                'catalog-list__list_grid': this.grid
            }
        },
        itemClasses() {
            return {
                'catalog-list__item_margin': this.itemMargin,
                'catalog-list__item_grid': this.grid
            }
        },
        iconSortClass() {
            return {
                'catalog-list__sort-icon_rotated': (this.sortType !== 'name' && this.sortOrder === 'asc') || (this.sortType === 'name' && this.sortOrder === 'desc')
            }
        },
        searchClasses() {
            return {
                'catalog-list__search_with-sort': this.withSort && this.sortInSearchField,
                'catalog-list__search_border': this.withSort && this.searchBorder,
                'catalog-list__search_full-bordered': this.searchFullBordered,
                'catalog-list__search_border-bottom': this.searchBorderBottom
            }
        },
        searchSortClasses() {
            return {
                'catalog-list__sort-wrapper_with-filter': this.withFilter && this.filterInSearchField,
            }
        },
        paginationClasses() {
            return {
                'catalog-list__pagination-wrapper_rightbar': this.paginationRightBar
            }
        },
        getSortType() {
            return get(this.sortOptions, `[${this.sortType}].name`)
        },
        isDefaultSort() {
            return Object.keys(this.sortOptions)[0] === this.sortType
                && this.defaultSort.order === this.sortOrder;
        },
        isSortDisabled() {
            return this.items && this.items.length <= 1;

            /* return !Object.keys(this.sortOptions).length */
        },
        showSortFilter() {
            return this.showSortAndFilter || this.withSort && !this.isDeviceDesktop || this.withFilter && !this.isDeviceDesktop
        }
    },
    mounted() {
        if (this.activeLetter) {
            if (this.activeLetter.match(/[a-z]/i)) {
                this.language = 'en'
            }

            // this.$scrollTo('#letter-' + this.activeLetter, {
            //     container: '#letters-slider',
            //     x: true,
            //     y: false,
            //     offset: -125
            // })
        }

        setTimeout(() => {
            this.loaded = true
        }, 1000)
    },
    methods: {
        get,
        showMore() {
            this.paginationPage = this.page + 1
        },
        emitQuery(value) {
            this.$emit('search', value);
        },
        getLetterTo(letter) {
            if (this.lettersUrl) {
                if(letter)
                    return url.modify(this.lettersUrl, {page: null}) + letter ? letter + '/' : '';
                
                return url.modify(this.lettersUrl, {page: null});
            }

            const path = process.server ? this.$route.fullPath : window.location.href;
            return url.modify(path, {letter, page: null});
        },
        activeLetterClass(letterName) {
            return {'catalog-list__letter-link_active': letterName === this.activeLetter}
        },
        showSort() {
            this.showPopup('popup-sort', {
                options: this.sortOptions,
                enOrder: this.enOrder,
                sort: this.sortType,
                order: this.sortOrder,
                withDefaultSortOrder: this.withDefaultSortOrder
            })
        },
        showFilter() {
            this.$emit('show-filter')
        },
        handleLetterSelected(letter) {
            this.$emit('letter-selected', {
                value: letter ? letter.name : null, 
                url: letter ? letter.htmlUrl : null
            });

            const isCurrentLetter = get(letter, 'name') === this.activeLetter;

            if(!letter || isCurrentLetter){
                if(!this.lettersUrl) return

                const params = {
                    sort: this.sortType,
                    order: this.sortOrder,
                    page: null
                };

                this.$url.set(url.modify(this.lettersUrl, params));
            }
        },
        listGridColumns(count) {
            if (count !== 0) return `catalog-list__list_grid-${count}`
        },
        showSortPopup() {
            if (this.isSortDisabled) return

            this.popupSortShowed = true
        },
        hideSortPopup() {
            this.popupSortShowed = false
        },
        setLoading() {
            this.$refs.search.loading = true;
        },
        unsetLoading() {
            this.$refs.search.loading = false;
        },
        load: throttle(async function() {
            if(this.isLoading || !this.catalogNextPageUrl || this.queryPending) return;

            this.catalogNextPageUrl = await this.getData(this.catalogNextPageUrl);
        }, 1000),
        async getData(pageUrl) {
            if (pageUrl) {
                this.isLoading = true
                const response = await this.$api.get(pageUrl)

                this.$emit('items-updated', get(response, 'data.items', false))

                this.isLoading = false
                return get(response.data, 'nextPageUrl', null)
            }
        }
    }
}
</script>

<style scoped>
.list-complete-item {
    transition: all .3s ease;
}
.list-complete-enter, .list-complete-leave-to
    /* .list-complete-leave-active до версии 2.1.8 */ {
    opacity: 0;
}
.slide-enter, .slide-leave-to{
    transform: scaleY(0);
}
</style>

<style lang="less">
.catalog-list {
    --grid-item-height: rem(251);

    .search-field__field {
        @{desktop} & {
            &::before {
                top: 50%;
                left: 0;

                transform: translateY(-50%);
            }
        }
    }

    .search__input {
        @{desktop} & {
            border: none;

            padding-left: rem(23);

            max-width: 97%;
        }
    }

    &__pagination-preloader {
        text-align: center;

        padding-top: rem(10);

        width: 100%;
        min-height: rem(46);

        @{desktop} {
            padding-top: 0;
        }
    }

    &__switcher {

        &-wrapper {
            list-style: none;

            padding-left: @dim-page-padding;

            position: relative;
            z-index: 2;

            @{desktop} & {
                padding-right: rem(14);
                padding-left: 0;
            }
        }
    }

    &__search {
        border-top: rem(1) solid @color-gray-white;

        .form__input {
            padding-top: 0;
            padding-right: rem(50);
            padding-left: rem(48);
        }

        @{desktop} & {
            border: none;
            border-bottom: rem(1) solid @color-gray-white;
            border-radius: 0;

            margin-top: 0;
            margin-bottom: rem(8);
            padding-right: 0;
            padding-left: rem(16);

            @{desktop} & {
                margin-bottom: 0;
            }

            .form__input {
                font-size: rem(14);

                height: rem(42);
            }
        }

        &_with-sort {
            @{desktop} & {
                border-bottom: rem(1) solid @color-gray-white;
                border-radius: 0;
                border-top: none;

                padding-left: 0;
            }
        }

        &_full-bordered {
            @{desktop} & {
                border: rem(1) solid @color-gray-2;
                border-radius: rem(3);

                margin-top: 0;
                margin-bottom: rem(8);
                padding-right: 0;
                padding-left: rem(16);

                .animate(border-color);

                &:hover {
                    border: rem(1) solid @color-gray-light;
                }
            }
        }
    }

    &__letters-slider {
        margin: 0;
        padding: rem(12) 0 rem(12);
        padding-left: rem(16);

        width: 100%;

        @{desktop} & {
            justify-content: space-between;

            padding: 0;
            padding-top: rem(2);

            &[data-justify] {
                justify-content: flex-start;

                .catalog-list__letter {
                    margin-right: rem(20);

                    width: rem(17);
                }
            }
        }

        &-container {
            display: flex;
            align-items: center;

            position: relative;

            @{desktop} & {
                padding-bottom: rem(7)
            }

            &:before, &:after {
                content: '';

                background: @color-white;
                box-shadow: inset 0 0 27px 6px @color-white, 0 0 12px 8px @color-white;

                height: 100%;
                width: rem(9);

                position: absolute;
                z-index: 1;

                opacity: .8;

                pointer-events: none;

                @{desktop} & {
                    display: none;
                }
            }

            &:before {
                top: 0;
                left: 0;

                @{desktop} & {
                    left: rem(-9);
                }
            }

            &:after {
                top: 0;
                right: 0;

                @{desktop} & {
                    right: rem(-9);
                }
            }

            &[data-switcher=true]:before {
                left: rem(82);
            }
        }

        &-wrapper {
            background: @color-white;
            border-bottom: rem(1) solid @color-gray-white;

            position: static;

            overflow: hidden;

            transition: all .15s ease;

            &[aria-hidden=true] {
                opacity: 0;
            }

            &.vue-sticky-el {
                /* Для sticky */
                // top: 0 !important;
                z-index: 2;

                &.top-sticky{
                    .fixed-header-show & {
                        transform: translateY(rem(56));
                        // top: rem(56) !important;
                    }
                }
            }

            @{desktop} & {
                border-bottom: none;
            }
        }
    }

    &__letter {
        display: flex;
        justify-content: center;

        font-size: rem(16);
        font-weight: 500;
        white-space: nowrap;

        margin-right: rem(27);

        height: rem(20);

        &:last-child {
            padding-right: 2rem;

            @{desktop} & {
                margin-right: 0;
                padding-right: 0;
            }
        }

        &_all{
            text-transform: uppercase;
            position: sticky;
            left: 0;
            top: 0;
            background-color: @color-white;

            &::before,
            &::after{
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                display: none;

                @{mobile} & {
                    display: block;
                }
            }

            &::before{
                left: rem(-16);
                background-color: @color-white;
                width: rem(16);
            }

            &::after{
                background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.82) 40%, white 75%);
                left: 100%;
                width: rem(25);
            }
        }

        @{desktop} & {
            color: @color-gray-light;
            font-size: rem(14);

            margin: 0;

            height: auto;

            &:first-child .catalog-list__letter-link {
                padding-left: 0;
            }

            &:last-child .catalog-list__letter-link {
                padding-right: 0;
            }
        }
    }

    &__letter-link {
        color: @color-black;

        @{desktop} & {
            display: block;

            color: @color-gray-light;

            padding: rem(6);

            transition: color 0.2s;

            .hover({
                color: @color-black;
                transition: color 0.2s;
            });
        }

        &_active {
            color: @color-primary;

            @{desktop} & {
                color: @color-primary;
            }
        }
    }

    &__sort-and-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;

        background: @color-white;
        border-bottom: rem(1) solid @color-gray-white;
        font-size: rem(12);

        padding-top: rem(15);
        padding-bottom: rem(13);

        top: 0 !important;
        transition: transform .15s ease;
        .page-padding();

        &.top-sticky{
            .fixed-header-show & {
                /* Для sticky */
                // top: rem(56) !important;
                transform: translateY(rem(56));
            }
        }

        @media @min375 {
            font-size: rem(13);
        }

        @{desktop} & {
            justify-content: flex-end;

            padding: 0;
            padding-left: 50%;

            height: rem(48);

            position: relative;
            top: 0 !important;
        }

    }

    &__sort-and-filter + &__list {
        border-top: none;
    }

    &__sort {

        @{desktop} & {
            font-size: rem(13);

            height: rem(45);
        }

        &-type {
            font-weight: 500;

            padding-left: rem(4);
        }

        &-icon {
            height: rem(11);
            width: rem(11);

            position: relative;
            top: rem(-1);
            left: rem(2);

            transition: transform .15s ease;

            &_rotated {
                transform: rotateZ(180deg);
            }
        }

        &-wrapper {
            // flex-shrink: 0;
            // position: relative;
            position: absolute;
            top: rem(2);
            left: 0;

            &_search {
                top: rem(-1);
                right: 0;
                left: auto;
            }

            @{desktop} & {
                white-space: nowrap;
            }

            &_with-filter {
                right: rem(110);
            }
        }

        &-wrapper:hover button:not([disabled]) &-type {
            @media @hover {
                color: @color-primary;
            }
        }


        &_search {
            text-align: right;

            width: 100%;
        }

        &[disabled] {
            pointer-events: none;
        }
    }

    &__filter {
        font-weight: 500;

        .hover({
            color: @color-primary;
        });

        &_search {
            font-size: rem(13);
            text-align: right;

            width: 100%;
            max-width: rem(110);
        }
    }

    &__sort,
    &__filter {
        background: none;
        border: none;

        padding: 0;

        transition: color .3s ease;
    }

    &__list {
        border-top: rem(1) solid @color-gray-white;
        list-style-type: none;

        margin: 0;
        padding: 0;

        &_grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: minmax(min-content, max-content);
            gap: rem(24);

            padding-top: rem(32);
            padding-bottom: rem(24);
            .page-padding();

            @{desktop} & {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(rem(342), 1fr));
                row-gap: rem(20);

                border-top: none;
                box-sizing: border-box;
                column-gap: rem(22);

                padding-top: rem(16);

                &-6 {
                    grid-template-columns: repeat(auto-fit, 13%);
                    row-gap: rem(30);
                }

                &-5 {
                    grid-template-columns: repeat(auto-fit, rem(195));
                }

                &-4 {
                    grid-template-columns: repeat(auto-fit, 25%);
                    gap: 0;
                }

                &-3 {
                    grid-template-columns: repeat(auto-fit, minmax(rem(250), rem(342)));
                }
            }
        }
    }

    &__letters-slider-wrapper + &__list {
        border-top: none;
    }

    &__item {
        list-style: none;

        &:not(:last-of-type) {
            border-bottom: rem(1) solid @color-gray-white;
        }

        &_grid {
            @{desktop} & {
                border: none;

                height: var(--grid-item-height);

                position: relative;

                &:not(:last-of-type) {
                    border: none;
                }
            }
        }

        &_margin {
            @{desktop} & {
                &:not(:last-of-type) {
                    margin-bottom: rem(16);
                }
            }
        }
    }

    &__list_grid &__item {
        border-bottom: none;
    }

    &__pagination {
        margin-top: rem(10);

        //max-width: rem(380);

        &-wrapper {
            display: flex;
            justify-content: center;

            border-top: rem(1) solid @color-gray-white;

            padding-top: rem(7);
            padding-bottom: rem(18);

            width: 100%;

            &_single-mode {
                padding: 0;
            }

            &_rightbar {
                border: none;
            }

            @{desktop} & {
                border-top: none;
            }
        }

        // @{desktop} & {
        //     max-width: 100%;
        // }
    }

    &__empty-result {
        &_content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;

            border-top: none;
            color: @color-gray-light;
            text-align: center;
            word-break: break-word;

            padding-top: rem(12);

            min-height: rem(200);

            overflow: hidden;

            .page-padding();

            @{desktop} & {
                min-height: 26.5vh;
            }
        }
    }

    &__empty{
        &-reset{
            margin-top: rem(20);
            font-weight: 500;
            min-width: rem(160);
        }
    }

    .loading-layer {
        background: #b0b0b0;

        height: 100%;
        width: 100%;

        position: absolute;
        z-index: 10;

        opacity: .5;
    }
}
</style>
